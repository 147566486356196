import React from "react";
import { ReactComponent as Logo } from '../../assets/logo.svg'
import './styles.scss'

const Header = () => {

  return (
    <header>
      <div className="header">
        <div className="logo">
          <Logo />
        </div>
        <ul className="nav">
          {/* <li className="nav__item">Home</li>
          <li className="nav__item">Features</li>
          <li className="nav__item">About</li>
          <li className="nav__item">Contact Us</li> */}
          <li className="nav__item">
            <div className="nav__item-button" onClick={(e) => {
              let target = document.querySelector("#contact")
              target.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              })
              History.pushState(null, null, "#contact")
            }}>Sign Up</div>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Header