import React from "react";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import './styles.scss'

const Footer = () => {

  const initialValues = {
    email: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log('---', values)
    }
  })

  return (
    <footer className="container">
      <div className="footer">
        <div className="footer__logo">
          <Logo />
        </div>
      </div>
      {/* <div className="sections">
        <div className="sections__storeicons">
          <a href="#" className="storeicon">
            <img src="/play-store.svg" />
          </a>
          <a href="#" className="storeicon">
            <img src="/app-store.svg" />
          </a>
        </div>
        <div className="sections__nav">
          <ul className="nav">
            <li className="nav__link">
              <a href="#">Home</a>
            </li>
            <li className="nav__link">
              <a href="#">Features</a>
            </li>
            <li className="nav__link">
              <a href="#">About</a>
            </li>
            <li className="nav__link">
              <a href="#">Contact Us</a>
            </li>
          </ul>
          
          <ul className="nav sublinks">
            <li className="nav__link sublink">
              <a href="#">Privacy Policy</a>
            </li>
            <li className="nav__link sublink">
              <a href="#">Terms & Services</a>
            </li>
            <li className="nav__link sublink">
              <a href="#">Terms of Use</a>
            </li>
          </ul>
        </div>
        <div className="sections__subscribe">
          <div className="title">Join Our Newsletter</div>
          <form className="form" onSubmit={formik.handleSubmit}>
            <input
              className={formik.errors.email && "has-error"}
              value={formik.values.email}
              placeholder="Your email address"
              onChange={formik.handleChange("email")}
            />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div> */}
      <div className="copyright">
        Copyright © 2023. All rights reserved.
      </div>
    </footer>
  )
}

export default Footer