import React, { useState } from "react";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios'
import { ReactComponent as Growth } from "../../assets/icons/growth.svg";
import { ReactComponent as About } from "../../assets/icons/about.svg";
import { ReactComponent as Pricing } from "../../assets/icons/pricing.svg";
import { ReactComponent as Contact } from "../../assets/icons/contact.svg";
import './styles.scss'

// const apiUrl = 'http://localhost:8000/api'
const apiUrl = 'https://caterctrl-qa.herokuapp.com/api';

const Home = () => {
  const [active, setActive] = useState(0)
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const navs = ["Temperature Logs", "Daily Checklists", "Cleaning Schedules", "Prep Lists", "Closing Statements"]

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().email().required("Required"),
      phone: Yup.string().required("Required"),
      message: Yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      axios({
        url: `${apiUrl}/contact-us`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: values
      })
        .then(() => {
          formik.resetForm()
          setShowSuccess(true)
          setLoading(false)
          setTimeout(() => {
            setShowSuccess(false)
          }, 3000)
        })
        .catch(() => setLoading(false))
    }
  })

  return (
    <>
      <div className="app">
        <div className="app__bg">
          <div className="app__phones" />
          <div className="app__about" style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}>
            <div className="app__title">
              CaterCTRL - the Digital<br />
              Kitchen Companion
            </div>
            <div className="app__description">
              SFBB Folders, scribbled prep lists and missing order cut offs are a thing of the past
            </div>
            <div className="app__button" style={{ width: 350, justifySelf: "center" }} onClick={() => {
              let target = document.querySelector("#contact")
              target.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              })
            }}>Sign up for our Beta release</div>
          </div>
        </div>
      </div>

      <div className="fn container">
        <div className="fn__title">
          <Growth />
          <div>Our Benefits <b>&</b> Features</div>
        </div>
        <ul className="fn__nav">
          {navs.map((nav, i) =>
            <li
              key={i}
              className={`fn__nav-item${i === active ? " active" : ""}`}
              onClick={() => setActive(i)}
              style={{width:i === 0 ? 150 : i === 1 ? 132 : i === 2 ? 164 : i === 3 ? 78 : 164}}
            >
              {nav}
            </li>
          )}
        </ul>
        {active === 0 && <div className="fn__tab">
          <div className="fn__tab-log-img" />
          <div className="fn__tab-log-text">
            <div className="title">Temperature Logs</div>
            <div className="desc">
              Managing your critical control points during a busy work day is paramount to providing your clients with food that is not only delicious, but also prepared within the guidelines set out by the <b>FSA</b>.
            </div>
            <div className="desc">
              Within <b>CaterCTRL</b>, you can record temperatures for all of your cold storage; deliveries; cooking, cooling, reheating & hot holding. Reminders are sent at key times so that none of this is missed. Each data log is timestamped and associated with the individual inputting the information.
            </div>
            {/* <a href="#" className="button">See more</a */}
          </div>
        </div>}
        {active === 1 && <div className="fn__tab">
          <div className="fn__tab-log-img" />
          <div className="fn__tab-log-text">
            <div className="title">Daily Checklists</div>
            <div className="desc">
              Never miss a mandatory daily check again! All of your daily checks can be done in one place and signed off by any members of your team you assign those permissions to.
            </div>
            <div className="desc">
              These logs, like
              everything else recorded using our system are stored in an easily accessible file making it easy to
              export for internal review or an external inspection by the environmental health officer.
            </div>
            {/* <a href="#" className="button">See more</a */}
          </div>
        </div>}
        {active === 2 && <div className="fn__tab">
          <div className="fn__tab-log-img" />
          <div className="fn__tab-log-text">
            <div className="title">Cleaning Schedules</div>
            <div className="desc">
              Set daily, weekly and monthly tasks, visible to every member of your team. Provide additional
              information such as products to be used or the correct PPE required.
            </div>
            <div className="desc">
              Once a job has been
              completed, this can be marked in the app and randomised photos will be requested so that you
              can ensure that your high standards are being upheld.
            </div>
            {/* <a href="#" className="button">See more</a */}
          </div>
        </div>}
        {active === 3 && <div className="fn__tab">
          <div className="fn__tab-log-img" />
          <div className="fn__tab-log-text">
            <div className="title">Prep Lists</div>
            <div className="desc">
              Flag important jobs and set deadlines when these need to be completed. Tasks that you do
              regularly such as yorkies for your Sunday roast can be marked as recurring to make next weeks
              lists easier to create.
            </div>
            <div className="desc">
              Keep track of how often you are preparing items and manage your prep
              quantities.
            </div>
            {/* <a href="#" className="button">See more</a */}
          </div>
        </div>}
        {active === 4 && <div className="fn__tab">
          <div className="fn__tab-log-img" />
          <div className="fn__tab-log-text">
            <div className="title">Closing Statements</div>
            <div className="desc">
              CaterCTRL does all of this and much more saving you and your team valuable time and your
              business money. Never again will you lose an important document, miss an order deadline or be
              unsure of when a temperature was recorded giving you peace of mind that your kitchen is running
              smoothly and compliantly.
            </div>
            <div className="desc">
              Prices start from just £1 per day and the app will be individually tailored to your kitchen’s
              requirements by our team.
            </div>
            {/* <a href="#" className="button">See more</a */}
          </div>
        </div>}
      </div>
      <div className="design">
        <div className="title">
          The <b>CaterCTRL</b> platform has been<br />
          designed by <b>Chefs for Chefs</b>.
        </div>
        <div className="design__container">
          <div className="design__container-item">
            We have taken an in depth look at the processes most food businesses in the UK follow and found a better way for all of this to be managed.
            There aren’t many chefs that can say that they have never misplaced a sheet from their daily diary or spilt their morning coffee over their temperature logs leading to an uncomfortable conversation with the environmental health officer.
          </div>
          <div className="design__container-item">
            Prep lists get left at home or on the train on your commute in to work or a necessary routine cleaning task gets forgotten after an unusually busy lunch shift.
            We’ve all missed an order cut off time or forgotten to write that vital ingredient for tomorrows specials on our list before changing out of our whites leaving us with a trip to the supermarket before work in the morning.
          </div>
        </div>
      </div>

      <div className="about container">
        <div className="about__title">
          {/* <About /> */}
          <div className="title">About Our App</div>
        </div>
        <div className="about__text">
          <div className="about__text-desc">
            <div className="section text">
              Managing a professional kitchen is no easy feat and nobody will understand the intricacies of yours as well as you do. We're here to offer a helping hand!<br />
              Messy stacks of inflexible food safety paperwork, overflowing folders of supplier invoices and a million scribbled notes with everything from recipes to prep lists and notes reminding the KP to rinse the bins have been a part of all of our working lives for so long, it may seem hard to imagine a different approach.
            </div>
            <div className="section text">
              We have taken all of this and more and put it in to an easy to use app allowing you to focus on what’s really important, providing your guests an amazing dining experience!
            </div>
          </div>
          <div className="about__text-img" />
        </div>


        <div className="about__title">
          <div className="title center auto">
            We have taken all of the issues mentioned and<br />
            provided an efficient, digital solution
          </div>
        </div>
        <div className="about__text">
          <div className="about__text-desc">
            <div className="section text">
              <b>CaterCTRL</b> is designed to manage all of your food safety temperature controls, cleaning schedules, prep lists, ordering and daily checklists within an easy to use platform accessible by every member of your team.
            </div>
          </div>
          <div className="about__text-desc">
            <div className="section text">
              We understand how important each of these tasks are in the everyday running of any food business, not just for legal compliance but to uphold the standards you have set. Using <b>CaterCTRL</b> everyday will minimise the risk of key data not being logged at the right times, provide accountability and ensure that your kitchen keeps running smoothly.
            </div>
          </div>
        </div>
      </div>

      <img src="/phones-imgs.png" className="phones" />

      <div className="pricing container">
        <div className="pricing__title">
          <Pricing />
          <div className="title">Pricing</div>
        </div>
        <div className="pricing__desc">
          Prices start from just  <b>£1/per day</b> and the app will be individually tailored<br />
          to your kitchen's requirements by our team.
        </div>
      </div>

      <div className="contact" id="contact">
        <div className="contact__form">
          <div className="contact__form-title container ">
            <Contact />
            <div className="title">Contact Us</div>
          </div>
          <form onSubmit={formik.handleSubmit} className="container">
            <div className="contact__form-form">
              <div className="field-container">
                <label>First Name</label>
                <input
                  value={formik.values.firstName}
                  onChange={formik.handleChange("firstName")}
                  className={formik.errors.firstName && "has-error"}
                />
              </div>
              <div className="field-container">
                <label>Last Name</label>
                <input
                  value={formik.values.lastName}
                  onChange={formik.handleChange("lastName")}
                  className={formik.errors.lastName && "has-error"}
                />
              </div>
            </div>
            <div className="contact__form-form">
              <div className="field-container">
                <label>Email</label>
                <input
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                  className={formik.errors.email && "has-error"}
                />
              </div>
              <div className="field-container">
                <label>Phone Number</label>
                <input
                  value={formik.values.phone}
                  onChange={formik.handleChange("phone")}
                  className={formik.errors.phone && "has-error"}
                />
              </div>
            </div>


            <div className="field-container">
              <label>Message</label>
              <input
                value={formik.values.message}
                onChange={formik.handleChange("message")}
                className={formik.errors.message && "has-error"}
                placeholder="Write your message.."
              />
            </div>
            {/* { formik.errors.firstName && <div>{ formik.errors.firstName }</div> } */}

            {showSuccess && (<div className="successMsg">Your request has been successfully sent.</div>)}
            <button type="submit" className="button" disabled={loading}>Send Message</button>

          </form>
        </div>

        <div className="contact__img" />
      </div>
    </>
  )
}

export default Home
