import Footer from './components/footer';
import Header from './components/header'
import Home from './containers/home'
import './index.scss'

function App() {
  return (
    <>
      <Header />
      <Home />
      <Footer />
    </>
  );
}

export default App;
